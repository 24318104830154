.rootAbout {
  height: 100vh;
  margin-top: 6%;
  margin-right: 2%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.animationBackground {
  background-color: rgb(245, 119, 119);
  border-radius: 100%;
  height: 275px;
  width: 275px;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.WhoAmIContainer {
  background-color: white;
  margin-right: 2%;
  margin-top: -3%;
  width: 18%;
  height: 70%;
  border-radius: 30px;
  border: 4px solid black;
}

.WhoAmITitle {
  padding-top: 10%;
  font-size: 2em;
  border-bottom: 2px solid black;
  padding-left: 5%;
}
.WhoAmISubTitleContainer {
  padding-left: 6%;
}

.WhoAmISubTitle {
  padding-top: 2%;
  font-size: 1.5em;
}

.AboutMeContainer {
  background-color: white;
  width: 42%;
  height: 70%;
  border-radius: 30px;
  border: 4px solid black;
  margin-right: 6%;
  margin-top: -3%;
  /* padding: 1%; */
}

.websiteUI {
  border-bottom: 2px solid black;
  height: 5%;
  background-color: rgb(55, 55, 55);
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding-top: 3px;
  padding-left: 5px;
}
.buttonUX {
  padding-left: 1.5%;
}
.Button {
  border-radius: 4px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  background-color: black;
  color: white;
  cursor: pointer;
}
.AboutMeContent {
  padding: 30px;
  padding-top: 20px;
  font-size: 24px;
}
.AppContainerAboutMe {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10%;
}

@media only screen and (max-width: 400px) {
  .rootAbout {
    height: 100vh;
  }
}
