@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap");

.bg {
  animation: slide 40s ease-in-out infinite alternate;
  background-image: linear-gradient(
    -60deg,
    #89abe3ff 50%,
    #fcf6f5ff 50% /* rgb(151, 214, 250) 50%,
    rgb(235, 164, 255) 50% */
  );
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 60s;
}

.bg3 {
  animation-duration: 60s;
}
.contentContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 15%;
  padding-right: 15%;
}

.content {
  font-weight: 350;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 64px;
  color: gray;
}

@media only screen and (max-width: 400px) {
  .contentContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    font-weight: 350;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 28px;
    color: white;
  }
}
/* .name {
  font-weight: 550;
  animation: 14s infinite bgcolorchange;
} */

@keyframes bgcolorchange {
  0% {
    color: #e31c6d;
  }

  50% {
    color: rgb(46, 174, 212);
  }
  100% {
    color: #e31c6d;
  }
}

/* .name:hover {
  transition: 0.3s;
  color: rgb(46, 174, 212);
  opacity: 0.6;
} */

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
