@mixin fixedbox {
  box-sizing: border-box;
  display: block;
  font-size: 3em;
  padding: 0.5em;
  width: 2em;
  height: 2em;

  position: fixed;
  top: 3em;
  left: 3em;
  transform: translate3d(-50%, -50%, 0);
}
%fixedbox {
  @include fixedbox;
}
.content {
  // Basic styling
  @extend %fixedbox;
  font-weight: bold;
  text-align: center;
  z-index: 1000;
  padding-top: 12px;
  padding-left: 30px;
  margin-top: -47px;
  margin-left: -47px;
  // Cut-out text effect
  background: #fff;
  color: #000;
  mix-blend-mode: lighten;
}

section {
  // Background image styling
  background-repeat: no-repeat;
  background-size: cover;

  // Reset stacking context
  isolation: isolate;

  // Must be set to correctly position .wrapper below
  position: relative;

  // Height not required. Can be left as auto
  height: 100vh;
  padding: 1em;
}

.wrapper {
  // Hides box (&:before) outside of section
  clip: rect(auto auto auto auto);

  // Clip works only on positions absolute or fixed
  position: absolute;
  // Stretch to fill entire section
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:before {
    content: " ";

    // Basic styling
    @extend %fixedbox;
    padding: 2em 2em;

    // Blend with background
    mix-blend-mode: darken;
  }
}

@media only screen and (max-width: 1800px) {
  .content {
    // Basic styling
    @include fixedbox;
    font-weight: bold;
    text-align: center;
    z-index: 1000;
    padding-top: 18px;
    padding-left: 35px;
    // Cut-out text effect
    background: #fff;
    color: #000;
    mix-blend-mode: lighten;
  }

  section {
    // Background image styling
    background-repeat: no-repeat;
    background-size: cover;

    // Reset stacking context
    isolation: isolate;

    // Must be set to correctly position .wrapper below
    position: relative;

    // Height not required. Can be left as auto
    height: 100vh;
    padding: 1em;
  }

  .wrapper {
    // Hides box (&:before) outside of section
    clip: rect(auto auto auto auto);

    // Clip works only on positions absolute or fixed
    position: absolute;
    // Stretch to fill entire section
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:before {
      content: " ";

      // Basic styling
      @include fixedbox;
      padding: 2em 2em;

      // Blend with background
      mix-blend-mode: darken;
    }
  }
}

.one {
  // Section background image
  background-image: url(https://images.pexels.com/photos/450066/pexels-photo-450066.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);

  .wrapper:before {
    // Box background color
    background: red;
    mix-blend-mode: color-burn;
  }
}

.two {
  background-image: url(https://images.pexels.com/photos/518245/pexels-photo-518245.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);

  .wrapper:before {
    background: #faaa54;
    mix-blend-mode: difference;
  }
}

.three {
  background-image: url(https://images.pexels.com/photos/163999/pexels-photo-163999.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);

  .wrapper:before {
    background: #6c320a;
    mix-blend-mode: color-dodge;
  }
}

.four {
  background-image: url(https://images.pexels.com/photos/132193/pexels-photo-132193.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);

  .wrapper:before {
    background: #1ca0f8;
    mix-blend-mode: darken;
  }
}
