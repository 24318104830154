.AppButton {
  border: 1px solid black;
  border-radius: 10px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: chocolate;
}

.animationApp {
  width: 100%;
}

.appTitle {
  display: flex;
  justify-content: center;
}

.appContainer {
  width: 100px;
  height: 130px;
}
