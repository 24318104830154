.skillContainer {
  display: flex;
  flex-wrap: wrap;
}
.skillIcons {
  width: 175px;
  display: flex;
  flex-direction: column;
  margin-right: 9%;
}
.skillIconsTitle {
  text-align: center;
  width: 175px;
}
