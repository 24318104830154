.CardRoot {
  background-color: rgb(233, 233, 233);
  border-radius: 30px;
  border: 4px solid black;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  width: 500px;
  height: 1100px;
}

.phoneUX {
  background-color: rgb(66, 68, 68);
  width: 492px;
  margin-left: -5px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid black;
}
.Phonestuff {
  display: flex;
  justify-content: space-between;
  width: 470px;
  padding-left: 12px;
  padding-top: 10px;
  color: white;
  font-size: 12px;
}
.URL {
  background-color: rgb(87, 89, 89);
  height: 30px;
  border: black solid 2px;
  border-radius: 8px;
  margin-top: 20px;
  width: 480px;
  padding-left: 8px;
  margin-left: 5px;
  color: rgb(235, 235, 235);
}
.InfoContainer {
  margin-left: 5px;
}
.PhoneTitle {
  text-align: center;
  font-size: 38px;
  font-weight: 600;
  padding-top: 18px;
  padding-bottom: 18px;
}
.Image {
  border-radius: 30px;
  width: 480px;
  border: 1px solid black;
}
.label {
  color: gray;
  font-size: 28px;
  padding-top: 20px;
}
.response {
  font-size: 20px;
  padding-bottom: 20px;
}
.InfoCard {
  width: 490px;
  font-weight: 350;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  color: black;
  padding-top: 12px;
  padding-left: 20px;
  flex-wrap: wrap;
}
.name {
  color: black;
  font-size: 40px;
  font-weight: 600;
}
.AboutCard {
  width: 450px;
  font-size: 28px;
}
.subSummaryContainer {
  width: 50%;
}
.summaryContainer {
  display: flex;
}

@media only screen and (max-width: 1800px) {
  .CardRoot {
    background-color: rgb(233, 233, 233);
    border-radius: 30px;
    border: 4px solid black;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    width: 400px;
    height: 90%;
  }
  .phoneUX {
    background-color: rgb(66, 68, 68);
    width: 101.5%;
    margin-left: -5px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid black;
  }

  .Phonestuff {
    display: flex;
    justify-content: space-between;
    width: 385px;
    padding-left: 12px;
    padding-top: 10px;
    color: white;
    font-size: 12px;
  }
  .URL {
    background-color: rgb(87, 89, 89);
    height: 30px;
    border: black solid 2px;
    border-radius: 8px;
    margin-top: 20px;
    width: 380px;
    padding-left: 8px;
    margin-left: 5px;
    color: rgb(235, 235, 235);
  }
  .InfoContainer {
    margin-left: 5px;
    height: 100%;
  }
  .PhoneTitle {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding-top: 12px;
    padding-bottom: 18px;
  }
  .Image {
    border-radius: 30px;
    width: 98%;
    border: 1px solid black;
  }
  .label {
    color: gray;
    font-size: 20px;
    padding-top: 10px;
  }
  .response {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .InfoCard {
    width: 100%;
    font-weight: 350;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    color: black;
    padding-top: 8px;
    padding-left: 20px;
    flex-wrap: wrap;
  }
  .name {
    color: black;
    font-size: 30px;
    font-weight: 600;
  }
  .AboutCard {
    width: 100%;
    font-size: 28px;
  }
  .subSummaryContainer {
    width: 50%;
  }
  .summaryContainer {
    display: flex;
  }
}
